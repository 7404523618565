import React from 'react'
import Navbar from './components/ui/Navbar/Navbar'
import MainRoutes from './routing/MainRoutes'
import Footer from './components/ui/Footer/Footer'
import 'antd/es/style/index';
import ScrollToTop from './components/ui/scrollTotop/ScrollToTop';

const App = () => {

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <MainRoutes />
      <Footer />
    </>
  )
}

export default App
import "./Footer.scss";
import yt from "../images/youtube.svg";
import fb from "../images/facebook.svg";
import ig from "../images/instagram.svg";
import { NavLink } from "react-router-dom";
import AppStore from "../images/App Store - Filled.png";
import GooglePlay from "../images/Google Play.png";
import { useTranslation } from "react-i18next";
import NetKgTracker from "../NetKgTracker"; //PROD: only for production. UAT: nothing

const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <div className="footer">
      <div className="content">
        <div>
          <br />
          <br />
          {<NetKgTracker />} {/* PROD: only for production. UAT: nothing */}
          <div className="project">
            {/* <GoogleAnalyticsWidget /> */} {/* hide total amount */}
          </div>
        </div>
        <div className="linkSide">
          <div className="links">
            <p>{t("footer.nav")}</p>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/"
            >
              {t("footer.main")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/petitions"
            >
              {t("footer.petitions")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/analytics"
            >
              {t("footer.analytics")}
            </NavLink>
          </div>
          <div className="project">
            <p>{t("footer.project")}</p>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/about"
            >
              {t("footer.about")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/contacts"
            >
              {t("footer.contacts")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/privacy"
            >
              {t("footer.privacy")}
            </NavLink>
          </div>
          <div className="socials">
            <h1>{t("footer.contacts")}</h1>
            <div className="icons">
              <a href="https://www.youtube.com/@TransparencyKyrgyzstan">
                <img src={yt} alt="YouTube" />
              </a>
              <a href="https://www.facebook.com/tikyrgyzstan">
                <img src={fb} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/transparency_kg">
                <img src={ig} alt="Instagram" />
              </a>
            </div>
            <br />
            <div className="download-section">
              <h1>{t("footer.download")}</h1>
              <div className="footer__apps-btns">
                <a
                  href="https://play.google.com/store/apps/details?id=kg.transparency.law"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={GooglePlay} alt="Google Play" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/jmd/id6730121606"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={AppStore} alt="App Store" />
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="copyright">
        <hr style={{ margin: "0", color: "white" }} />
        <p className="text" style={{ margin: "0" }}>
          {"© Copyright " +
            new Date().getFullYear() +
            " - " +
            t("footer.copyright")}
          <br />
          <br />
          {t("footer.EU")}
        </p>
      </div>
    </div>
  );
};

export default Footer;

"use client";

import { useDispatch, useSelector } from "react-redux";
import NewsCard from "../newsCard/NewsCard";
import "./NewsList.scss";
import { useEffect } from "react";
import { getNews } from "../../../store/news/newsActions";
import Loader from "../../ui/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { changePage } from "../../../store/news/newsSlice";
import { Pagination } from "antd";

const NewsList = () => {
  const { news, loading, currentPage, totalPages, totalNews } = useSelector(
    (state) => state.news
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");

    if (page && Number(page) !== currentPage) {
      dispatch(changePage({ page }));
      dispatch(getNews());
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch, currentPage]);

  const handlePageChange = (page) => {
    dispatch(changePage({ page }));
    navigate(`?page=${page}`);
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "4rem",
            marginBottom: "4rem",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="news-list">
          <ul>
            {news?.map((news) => (
              <li key={news.id}>
                <NewsCard
                  id={news.id}
                  title={news.title}
                  text={news.text}
                  image={news.image}
                  date={news.date}
                />
              </li>
            ))}
          </ul>

          {totalPages > 0 && (
            <div className="news-pagination">
              <Pagination
                current={currentPage}
                pageSize={8}
                total={totalNews}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NewsList;

import { createSlice } from "@reduxjs/toolkit";
import { addNewsComment, getNews, getNewsDetails } from "./newsActions";

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        news: [],
        loading: false,
        status: "",
        currentPage: 1,
        totalPages: 1,
        limit: 8,
        totalNews: 0,
        newsDetails: null,
    },
    reducers: {
        clearOneNewsState: (state) => {
            state.newsDetails = null
            state.status = ''
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.page;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNews.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNews.fulfilled, (state, action) => {
                state.loading = false;
                state.news = action.payload.results;
                state.totalPages = action.payload.totalPages;
                state.totalNews = action.payload.totalNews;
            })
            .addCase(getNews.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getNewsDetails.pending, (state) => {
                state.loadingDetails = true;
            })
            .addCase(getNewsDetails.fulfilled, (state, action) => {
                state.loadingDetails = false;
                state.newsDetails = action.payload.data;
            })
            .addCase(getNewsDetails.rejected, (state) => {
                state.loadingDetails = false;
            })
            .addCase(addNewsComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewsComment.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action?.payload?.data?.statusText;
            })
            .addCase(addNewsComment.rejected, (state) => {
                state.loading = false;
                alert("Не удалось опубликовать комментарий. Произошла ошибка")
                state.status = "error";
            })
    }
});

export const {
    clearOneNewsState,
    changePage,
} = newsSlice.actions;
export default newsSlice.reducer;